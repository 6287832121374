<template>
	<div class="block-wrap">
		<div class="block">
			<div class="item">
				<b>Name:</b><span>{{ user.name || '-' }}</span>
			</div>
			<div class="item">
				<b>Email:</b><span>{{ user.email || '-' }}</span>
			</div>
			<div class="item">
				<b>Artworks:</b
				><router-link v-if="user.artworkCount" :to="{ name: 'AdminInspectEntityArtworks' }">{{
					prettyNr(user.artworkCount, $imperial)
				}}</router-link
				><span v-else>-</span>
			</div>
			<div class="item">
				<b>Contributed:</b><span>{{ prettyNr(user.contributedCount, $imperial) || '-' }}</span>
			</div>
			<div class="item">
				<b>Rooms:</b
				><router-link v-if="user.roomCount" :to="{ name: 'AdminInspectEntityRooms' }">{{
					prettyNr(user.roomCount)
				}}</router-link
				><span v-else>-</span>
			</div>
			<div class="item"><b>Links:</b><span v-if="!user.links || user.links.length">-</span></div>
			<div class="item">
				<ul>
					<li v-for="(url, i) in user.links" :key="i">
						<a :href="url">{{ trunc(url) }}</a>
					</li>
				</ul>
			</div>
		</div>

		<div class="block">
			<div class="item"><b>About:</b> <span v-if="!user.about">-</span></div>
			<div class="item">
				{{ user.about }}
			</div>
		</div>

		<div class="block">
			<div class="item">
				<b>Id:</b
				><a
					href="#"
					class="id"
					@click.prevent="e => copyText({ text: `{ id: '${user.id}' }`, elm: e.target })"
					>{{ user.id }}</a
				>
			</div>
			<div class="item">
				<b>Username:</b><span>{{ user.username }}</span>
			</div>
			<div class="item">
				<b>Joined:</b><span>{{ prettyDate(user.created) || '-' }}</span>
			</div>
			<div class="item center-align"><b>Endorse:</b><EndorseDisplay :level="user.endorse" /></div>
			<div class="item">
				<b>Index:</b><span>{{ prettyNr(user.index) || '-' }}</span>
			</div>
		</div>
	</div>

	<div class="sep"></div>

	<div class="block">
		<h3>Rooms</h3>
		<div class="item columns">
			<ul>
				<li v-for="(room, i) in user.rooms" :key="i">
					<router-link
						:to="{
							name: 'AdminInspectEntityInfo',
							params: {
								entityType: 'room',
								id_username_namePath: room.namePath,
							},
						}"
						>{{ room.name }}</router-link
					>
					-
					<router-link
						:to="{
							name: 'EntityById',
							params: { id: room.id },
						}"
						>visit</router-link
					>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
// Components
import EndorseDisplay from '@/components/EndorseDisplay'

// Internal
import copyText from '@/use/CopyText'
import { prettyNr, trunc, prettyDate } from '@/use/StringMagic'

export default {
	name: 'AdminInspectEntityInfoUser',
	components: { EndorseDisplay },
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return { copyText, prettyNr, trunc, prettyDate }
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity-info.scss"></style>
