<template>
	<div class="block-wrap">
		<div class="block">
			<div class="item">
				<b>Name:</b><span>{{ room.name }}</span>
			</div>
			<div class="item">
				<b>Name Path:</b><span>{{ room.namePath }}</span>
			</div>
			<div class="item">
				<b>Artworks:</b><span>{{ prettyNr(room.artworkCount, $imperial) || '-' }}</span>
			</div>
			<div class="item">
				<b>Owner:</b>
				<span v-if="room.owner">
					<router-link
						:to="{
							name: 'AdminInspectEntity',
							params: {
								entityType: 'user',
								id_username_namePath: room.owner.username,
							},
						}"
						>{{ room.owner.name }}</router-link
					>
					&nbsp;-&nbsp;
					<router-link
						:to="{
							name: 'User',
							params: { username: room.owner.username },
						}"
						>profile</router-link
					></span
				><span v-else>-</span>
			</div>
		</div>

		<div class="block"></div>

		<div class="block">
			<div class="item">
				<b>Id:</b
				><a
					href="#"
					class="id"
					@click.prevent="e => copyText({ text: `{ id: '${room.id}' }`, elm: e.target })"
					>{{ room.id }}</a
				>
			</div>
			<div class="item">
				<b>User Path:</b><span>{{ room.owner.username }}</span>
			</div>
			<div class="item">
				<b>Name Path:</b><span>{{ room.namePath }}</span>
			</div>
			<div class="item">
				<b>Private:</b><span>{{ room.private ? 'Yes' : '-' }}</span>
			</div>
			<div class="item center-align"><b>Endorse:</b><EndorseDisplay :level="room.endorse" /></div>
		</div>
	</div>

	<div class="sep"></div>

	<div class="block-wrap">
		<div class="block">
			<div class="item"><b>Intro:</b> <span v-if="!room.intro">(later)</span></div>
			<div class="item text-block">
				{{ room.intro }}
			</div>
		</div>
	</div>
</template>

<script>
// Components
import EndorseDisplay from '@/components/EndorseDisplay'

// Internal
import copyText from '@/use/CopyText'
import { prettyNr } from '@/use/StringMagic'

export default {
	name: 'AdminInspectEntityInfoRoom',
	components: { EndorseDisplay },
	props: {
		room: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return { copyText, prettyNr }
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity-info.scss"></style>
