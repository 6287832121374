<template>
	<div class="block-wrap">
		<div class="block">
			<div class="item">
				<b>Title:</b><span>{{ artwork.title }}</span>
			</div>
			<div class="item">
				<b>Artist:</b
				><span
					><router-link
						:to="{
							name: 'AdminInspectEntity',
							params: {
								entityType: 'artist',
								id_username_namePath: artwork.artistNamePath,
							},
						}"
						>{{ artwork.artistName }}</router-link
					>&nbsp;-&nbsp;
					<router-link
						:to="{
							name: 'Artist',
							params: { namePath: artwork.artistNamePath, category: artwork.category },
						}"
						>profile</router-link
					></span
				>
			</div>
			<div class="item">
				<b>Year:</b><span>{{ artwork.year || '-' }}</span>
			</div>
			<div class="item">
				<b>Dimensions:</b><span>{{ artworkDimensions }}</span>
			</div>
			<div class="item">
				<b>Collectors:</b><span>{{ artwork.collectorCount || '-' }}</span>
			</div>
			<div class="item">
				<b>Rooms:</b><span>{{ artwork.roomCount || '-' }}</span>
			</div>
			<div class="item">
				<b>Contributor:</b>
				<span v-if="artwork.contributor">
					<router-link
						:to="{
							name: 'AdminInspectEntity',
							params: {
								entityType: 'user',
								id_username_namePath: artwork.contributor.username,
							},
						}"
						>{{ artwork.contributor.name }}</router-link
					>
					&nbsp;-&nbsp;
					<router-link
						:to="{
							name: 'User',
							params: { username: artwork.contributor.username },
						}"
						>profile</router-link
					></span
				><span v-else>-</span>
			</div>
		</div>

		<div class="block">
			<div class="item"><b>Labels:</b><span v-if="!artwork.labels || !artwork.labels.length">-</span></div>
			<div class="label-wrap" v-if="artwork.labels && artwork.labels.length">
				<router-link :to="{ name: 'Home' }" v-for="(label, i) in artwork.labels" :key="i" class="label">{{
					label
				}}</router-link>
			</div>
			<div class="item"><b>About:</b><span v-if="!artwork.about">-</span></div>
			<div class="item">
				{{ artwork.about }}
			</div>
		</div>

		<div class="block">
			<div class="item">
				<b>Id:</b
				><a
					href="#"
					class="id"
					@click.prevent="e => copyText({ text: `{ id: '${artwork.id}' }`, elm: e.target })"
					>{{ artwork.id }}</a
				>
			</div>
			<div class="item">
				<b>Artist Path:</b><span>{{ artwork.artistNamePath }}</span>
			</div>
			<div class="item">
				<b>Title Path:</b><span>{{ artwork.titlePath }}</span>
			</div>
			<div class="item">
				<b>Added on:</b><span>{{ prettyDate(artwork.created) || '-' }}</span>
			</div>
			<div class="item center-align"><b>Promoted:</b><EndorseDisplay :level="artwork.promoted" /></div>
			<div class="item center-align"><b>Endorse:</b><EndorseDisplay :level="artwork.endorse" /></div>
			<div class="item">
				<b>Is Mobile:</b><span>{{ artwork.isMobile ? 'yes' : 'no' }}</span>
			</div>
		</div>
	</div>

	<div class="sep"></div>
	<img :src="artwork.views ? artwork.views[0].src.replace(/__SIZE__/, 'small') : '/img/small/missing.jpg'" />
</template>

<script>
// Components
import EndorseDisplay from '@/components/EndorseDisplay'

// Internal
import copyText from '@/use/CopyText'
import { prettyDate } from '@/use/StringMagic'

export default {
	name: 'AdminInspectEntityInfoArtwork',
	components: { EndorseDisplay },
	props: {
		artwork: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return { copyText, prettyDate }
	},
	computed: {
		artworkDimensions() {
			const { height: h, width: w, depth: d, sizeUnit } = this.artwork
			const dim = { h, w, d }
			const keys = []
			const values = []
			for (const key in dim) {
				if (dim[key]) {
					values.push(dim[key])
					keys.push(key)
				}
			}
			const legend = `(${keys.join(' x ')})`
			return keys.length ? `${values.join(' x ')} ${sizeUnit} ${legend}` : '-'
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity-info.scss"></style>
