<template>
	<div class="status-display" :class="classStatus">
		{{ endorseLevel }}
	</div>
</template>

<script>
export default {
	name: 'StatusDisplay',
	props: {
		level: {
			type: [Number, Boolean],
			default: null,
		},
	},
	computed: {
		classStatus() {
			const levels = ['', 'approved', 'endorsed']
			if (typeof this.level == 'boolean') {
				return this.level ? levels[2] : levels[0]
			} else {
				return levels[this.level]
			}
		},
		endorseLevel() {
			if (typeof this.level == 'boolean') {
				return this.level ? 'Yes' : '-'
			} else {
				return this.level ? this.level : '-'
			}
		},
	},
}
</script>

<style scoped lang="scss">
.status-display {
	min-width: 0.1rem;
	height: 0.16rem;
	padding: 0 0.03rem;
	border-radius: $br;
	background: $black-10;
	text-align: center;
	font-size: 0.13rem;
	font-weight: 600;
	line-height: 0.16rem;
}
.status-display.approved {
	background: $good;
	color: #fff;
}
.status-display.endorsed {
	background: $gold;
	color: #fff;
}
</style>
