<template>
	<div class="block-wrap">
		<div class="block">
			<div class="item">
				<b>Name:</b><span>{{ artist.name || '-' }}</span>
			</div>
			<div class="item">
				<b>First Name:</b><span>{{ artist.firstName || '-' }}</span>
			</div>
			<div class="item">
				<b>Last Name:</b><span>{{ artist.lastName || '-' }}</span>
			</div>
			<div class="item"><b>Pseudonym</b><span>(later)</span></div>
			<div class="item">
				<b>Artworks:</b><span>{{ prettyNr(artist.artworkCount, $imperial) }}</span>
			</div>
			<div class="item">
				<b>Discovered by:</b>
				<span v-if="artist.discoveredBy">
					<router-link
						:to="{
							name: 'AdminInspectEntity',
							params: {
								entityType: 'user',
								id_username_namePath: artist.discoveredBy.namePath,
							},
						}"
						>{{ artist.discoveredBy.name }}</router-link
					>
					&nbsp;-&nbsp;
					<router-link
						:to="{
							name: 'User',
							params: { username: artist.discoveredBy.username },
						}"
						>profile</router-link
					></span
				><span v-else>-</span>
			</div>
		</div>

		<div class="block">
			<div class="item">
				<b>Born:</b><span>{{ artist.born || '-' }}</span>
			</div>
			<div class="item">
				<b>Died:</b><span>{{ artist.died || '-' }}</span>
			</div>
			<div class="item">
				<b>Website:</b><span>{{ artist.website || '-' }}</span>
			</div>
			<div class="item">
				<b>Wikipedia:</b
				><a
					v-if="artist.wikipedia"
					target="_blank"
					:href="`https://en.wikipedia.org/wiki/${artist.wikipedia}`"
					>{{ decodeURIComponent(artist.wikipedia) }}</a
				><span v-else>-</span>
			</div>
			<div class="item">
				<b>Links:</b><span v-if="!artist.otherLinks || !artist.otherLinks.length">-</span>
			</div>
			<div class="item">
				<ul>
					<li v-for="(url, i) in artist.otherLinks" :key="i">
						<a :href="url">{{ trunc(url) }}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="block">
			<div class="item">
				<b>Id:</b
				><a
					href="#"
					class="id"
					@click.prevent="e => copyText({ text: `{ id: '${artist.id}' }`, elm: e.target })"
					>{{ artist.id }}</a
				>
			</div>
			<div class="item">
				<b>Name Path:</b><span>{{ artist.namePath }}</span>
			</div>
			<div class="item center-align"><b>Endorse:</b><EndorseDisplay :level="artist.endorse" /></div>
			<div class="item">
				<b>Index:</b><span>{{ prettyNr(artist.index, $imperial) }}</span>
			</div>
		</div>
	</div>

	<div class="sep"></div>

	<div class="block-wrap">
		<div class="block">
			<div class="item"><b>About:</b> <span v-if="!artist.about">-</span></div>
			<div class="item text-block">
				{{ artist.about }}
			</div>
		</div>
	</div>
</template>

<script>
// Components
import EndorseDisplay from '@/components/EndorseDisplay'

// Internal
import copyText from '@/use/CopyText'
import { prettyNr, trunc } from '@/use/StringMagic'

export default {
	name: 'AdminInspectEntityInfoArtist',
	components: { EndorseDisplay },
	props: {
		artist: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return { copyText, prettyNr, trunc }
	},
	computed: {
		endorseLevel() {
			return this.artist.endorse === 0
				? '0 (Not approved)'
				: this.artist.endorse == 1
				? '1 (Approved)'
				: this.artist.endorse == 2
				? '2 (Endorsed)'
				: 'To be reviewed'
		},
	},
}
</script>

<style lang="scss" scoped src="@/assets/css/admin-inspect-entity-info.scss"></style>
