<template>
	<Artist v-if="entityType == 'artist'" :artist="entity" />
	<User v-if="entityType == 'user'" :user="entity" />
	<Artwork v-if="entityType == 'artwork'" :artwork="entity" />
	<Room v-if="entityType == 'room'" :room="entity" />
</template>

<script>
import Artist from '@/views/Admin/Inspect/Entity/Info/Artist'
import User from '@/views/Admin/Inspect/Entity/Info/User'
import Artwork from '@/views/Admin/Inspect/Entity/Info/Artwork'
import Room from '@/views/Admin/Inspect/Entity/Info/Room'

export default {
	name: 'AdminInspectEntityInfo',
	components: {
		Artist,
		User,
		Artwork,
		Room,
	},
	props: {
		// Parent
		entity: {
			type: Object,
			required: true,
		},

		// Route
		entityType: {
			type: String,
			required: true,
		},
	},
}
</script>
